<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <b-col cols="12" v-show="global.pendingRequests > 0">
                    <a-skeleton active :paragraph="{ rows: 10 }"/>
                </b-col>
                <b-col cols="12">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="mt-3">
                            <img src="/resources/images/logo.png" class="ml-3 max-w-100" alt="Emil Frey Logistics" width="100"/>
                            <div style="font-size: 1.10rem">{{ settings.logo }}</div>
                        </div>
                        <h2 class="m-0 p-0">
                            {{$t('title.deliveryRequest')}}
                        </h2>
                    </div>
                </b-col>
                <template v-if="details">
                    <div class="card w-100 mt-2" v-if="details.will_status !== 3 && !handleExpireDate(details.expires_at)">
                        <div class="card-header">
                            <h4>Hi, {{details.author ? details.author.name : ""}}</h4>
                            <div>{{$t('msc.deliveryRequestedForThisOrder')}}#{{details ? details.order_id : ""}}</div>
                            <div>{{$t('title.date')}}: {{$global.dateFormat(details.client_tr_at)}}</div>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive" v-show="details">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>{{$t("column.orderId")}}</th>
                                            <th>{{$t("column.vin")}}</th>
                                            <th>{{$t("column.brand")}}</th>
                                            <th>{{$t("column.model")}}</th>
                                            <th>{{$t("column.configCode")}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{details.order_id}}</td>
                                            <td>{{details.vin_number}}</td>
                                            <td>
                                                <span v-show="details.brand">
                                                    {{details.brand}}
                                                </span>
                                            </td>
                                            <td>
                                                <span v-show="details.model">
                                                    {{details.model}}
                                                </span>
                                            </td>
                                            <td>{{details.version_code}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr>
                            <b-form-group
                                :label="$t('input.comment')"
                                label-for="comment">
                                <b-textarea
                                        id="comment"
                                        v-model="comments"
                                        type="text"
                                        @focus="$event.target.select()"
                                ></b-textarea>
                            </b-form-group>
                            <hr>
                            <button class="btn btn-success" @click="handleAcceptDeliverRequest" :title="$t('button.accept')">{{$t('button.accept')}}</button>
                            <div class="text-right">
                                {{$t('title.createdBy')}}: {{details.client}}
                            </div>
                        </div>
                    </div>
                    <div class="card w-100 mt-5" v-else-if="details.will_status == 3 && !handleExpireDate(details.expires_at)">
                        <div class="card-body">
                            <h5 class="text-success">{{$t('msc.requestAccepted')}}</h5>
                        </div>
                    </div>
                </template>
                <div class="card w-100 mt-2" v-else>
                    <div class="card-body">
                        <h4 class="text-danger">Oops, </h4>
                        <p class="text-danger">{{$t('msc.linkHasBeenExpired')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import moment from 'moment-timezone'
    import {refresh} from "../../../util/Utils";

    const ACCEPT_STATE = {
        will_status: 3,
    }

    export default {
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                show: true,
                comments: null,
                details: null
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.deliveryRequest')

            if (this.$route.params.id !== undefined) {
                this.getDetail(this.$route.params.id)
            }
        },
        methods: {
            handleExpireDate(date) {
                return moment(date).isSame(moment().toDate()) || moment(date).isBefore(moment().toDate());
            },
            async handleAcceptDeliverRequest() {
                try {
                    const response = await request({
                        method: 'post',
                        url: `/remote/link/update`,
                        data: {...ACCEPT_STATE, id: this.$route.params.id, comments: this.comments}
                    })

                    this.itemUpdated()
                    setTimeout(() => {
                        refresh();
                    }, 2000)
                } catch (e) {
                    this.itemEditFails()
                }
            },
            async getDetail(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/remote/link/detail/${id}`,
                    })

                    const {data} = response
                    this.details = data
                } catch (e) {
                    // this.itemEditFails()
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
